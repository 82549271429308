import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaCalendarPlus, FaClipboardList, FaLaptopMedical, FaPowerOff } from 'react-icons/fa';
import sidebarBg from './assets/sidebar-bg.jpg';
import DocSymbol from './assets/doc-symbol.png';
import Avatar from './assets/avatar.png';
import AuthContext from './context/auth-context';

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {

  const loggedInUserName = localStorage.getItem('loggedInUserName');
  const loggedInUserType = localStorage.getItem('loggedInUserType');
  
  //console.log(loggedInUserType)

  const authCtx = useContext(AuthContext);

  const handleLogout = () =>{
    authCtx.onLogout();
  }
  
  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '11px 0 11px 80px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative'
          }}
        > 
          <img src={Avatar} alt="doc-icon" className='doc-icon'/>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <div className='welcome'>Welcome,</div>
            <span className='doctor_name' id='doctorName'>{loggedInUserName}</span>
            <div className='sub_heading'>&nbsp;&nbsp;</div>
          </div>
          
        </div>
      </SidebarHeader>

      <SidebarContent>
        {!(loggedInUserType === 'callcenter' || loggedInUserType === 'reception') && (
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaTachometerAlt />}
            >
              Dashboard
              <Link to="/" />
            </MenuItem>
          </Menu>
        )}
        
        <Menu iconShape="circle">
          <SubMenu
            title={'Appointments'}
            icon={<FaCalendarPlus />}
            open = {true}
          >
            <MenuItem>
              Appointments List
              <Link to="/appointment" /> 
            </MenuItem>
            <MenuItem>
              Consultation Queue
              <Link to="/consultation-queue-management" /> 
            </MenuItem>
            <MenuItem>
              Patients History
              <Link to="/consulted-patients-list" /> 
            </MenuItem>
            <MenuItem>
              Daily Report
              <Link to="/daily-report" /> 
            </MenuItem>
            <MenuItem>
              Doctor's Schedule
              <Link to="/schedule-management" /> 
            </MenuItem>
          </SubMenu>
        </Menu>
        
        {(loggedInUserType === 'admin' ) && (
          <Menu iconShape="circle">
            <SubMenu
              title={`Doctor's Portal`}
              icon={<FaLaptopMedical />}
            >
              <MenuItem
                suffix={<span className="badge red">New</span>}
              >
                Start Consultation
                <Link to="/consultaion" /> 
              </MenuItem>
              <MenuItem>
                Consultation History
                <Link to="/consultaion-history" /> 
              </MenuItem>
              <MenuItem>Medicines</MenuItem>
              <MenuItem>Diet Plans</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Reports</MenuItem>
            </SubMenu>
          </Menu>
        )}

        {(loggedInUserType === 'admin' ) && (
          <Menu iconShape="circle">
            <SubMenu
              title={'Utilities'}
              icon={<FaClipboardList />}
            >
              <SubMenu title={`Marketing`}>
                <MenuItem>SMS Marketing</MenuItem>
                <MenuItem>E-Mail Marketing</MenuItem>
              </SubMenu>
              <SubMenu title={`Reminder`}>
                <MenuItem>Revisit Reminder</MenuItem>
                <MenuItem>Vaccine Reminder</MenuItem>
              </SubMenu>
              <SubMenu title={`Medicine`}>
                <MenuItem>Stock Management</MenuItem>
                <MenuItem>Medicine Delivery</MenuItem>
                <MenuItem>Free Medicine Sample Stocks</MenuItem>
              </SubMenu>
              <MenuItem>Printouts</MenuItem>
            </SubMenu>
            {/* <SubMenu
              prefix={<span className="badge gray">3</span>}
              title={'withPrefix'}
              icon={<FaHeart />}
            >
              <MenuItem>submenu 1</MenuItem>
              <MenuItem>submenu 2</MenuItem>
              <MenuItem>submenu 3</MenuItem>
            </SubMenu>
            <SubMenu title={'multiLevel'} icon={<FaList />}>
              <MenuItem>submenu 1 </MenuItem>
              <MenuItem>submenu 2 </MenuItem>
              <SubMenu title={`submenu 3`}>
                <MenuItem>submenu 3.1 </MenuItem>
                <MenuItem>submenu 3.2 </MenuItem>
                <SubMenu title={`submenu 3.3`}>
                  <MenuItem>submenu 3.3.1 </MenuItem>
                  <MenuItem>submenu 3.3.2 </MenuItem>
                  <MenuItem>submenu 3.3.3 </MenuItem>
                </SubMenu>
              </SubMenu>
            </SubMenu> */}
          </Menu>
        )}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
            cursor: 'pointer'
          }}
          onClick={handleLogout}
        >
          <span
            className="sidebar-btn"
          >
            <FaPowerOff />
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              Log Out
            </span>
          </span>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
