import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/auth-context';
import { SnackbarProvider } from 'notistack';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <BrowserRouter>
//         <AuthContextProvider>
//             <App />
//         </AuthContextProvider>
//     </BrowserRouter>
// );

ReactDOM.render(
    <BrowserRouter>
        <AuthContextProvider>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </AuthContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);