import React, { useContext } from 'react';
import Layout from './Layout';
import LoginScreen from './components/LoginScreen/LoginScreen';
import './App.scss';
import AuthContext from './context/auth-context';

function App() {
  const authCtx = useContext(AuthContext);
  return(
    <>
      {authCtx.isLogin && <Layout />}
      {!authCtx.isLogin && <LoginScreen />}
    </>
  );
  
}

export default App;
