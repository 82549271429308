import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import AuthContext from './context/auth-context';
import Switch from 'react-switch';
import { FaBars } from 'react-icons/fa';
import Logo from './assets/SpotBooking.png';
import userImage from './assets/user-doctor.png';
import Loader from './assets/loader.gif';
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Logout from '@mui/icons-material/Logout';
import { FaRupeeSign } from 'react-icons/fa';

import { 
  Dashboard, 
  Appointment,
  VerifyAppointment,
  AppointmentDetails,
  CreateAppointment, 
  ConsultationQueue,
  DailyReport,
  ConsultedPatients,
  ConsultedPatientDetails, 
  ConsultedPatientHistory,
  Schedule, 
  ViewSchedule,
  CreateSchedule,
  StartConsultation,
  ConsultationHistory,

  Billing,
  AddNewUser,
  AppSettings,
  AddNewCallCenterGuidelines

} from './Router/Router'
import { iteratee } from 'lodash';


function Main ({collapsed, handleToggleSidebar, handleCollapsedChange}) {

  const authCtx = useContext(AuthContext);
  const loggedInUserType = localStorage.getItem('loggedInUserType');
  const selectedDoctor = localStorage.getItem('selectedDoctor');

  const navigate = useNavigate();

  const handleLogout = () =>{
    authCtx.onLogout();
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <main>      
      <header>
        <div className='logo_wrapper'>
          <img  src={Logo} alt="logo" />
        </div>   
        
        <div className="switch_btn_wrapper">
          <Switch
            height={16}
            width={30}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={handleCollapsedChange}
            checked={collapsed}
            onColor="#219de9"
            offColor="#bbbbbb"
          />
        </div>

        <Tooltip title="Account settings">
          <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 36, height: 36 }} src={userImage} alt="user-image">
              {/* <div className='user_image_wrapper'>
                <img src={userImage} alt="user-image" />
              </div> */}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>
            <ListItemIcon>
              <Avatar sx={{ width: 18, height: 18, color:'red' }} src={userImage} alt="user-image" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>             
          </MenuItem>

          {loggedInUserType === 'callcenter' ? '' : (
            <MenuItem onClick={()=>{navigate('/service-charge-details')}}>
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText >
                {loggedInUserType === 'admin' ? 'All Invoices' : 'Invoice'}
                <span 
                  className="badge red"
                  style={{marginLeft:'10px'}}
                >   
                    <FaRupeeSign />
                    <span 
                      id='walletBalance'
                    >
                      {loggedInUserType === 'admin' ? `${50000}` : `${1200}`}
                    </span>
                </span>
              </ListItemText>
              
            </MenuItem>
          )}
          
          <Divider />
          {loggedInUserType === 'admin' && (
            <MenuItem onClick={()=>{navigate('/add-new-user')}}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add User</ListItemText> 
            </MenuItem>
          )}

          {selectedDoctor !== null && (
            <MenuItem onClick={()=>{navigate('/settings')}}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText> 
            </MenuItem>
          )}

          

          {(loggedInUserType === 'admin' || 
          (localStorage.getItem('loggedInUserName') === 'Chayanika Das' && loggedInUserType === 'callcenter') ||
          (localStorage.getItem('loggedInUserName') === 'Urmi Das' && loggedInUserType === 'callcenter')) 
          && (
            <MenuItem onClick={()=>{navigate('/call-center-guidelines')}}>
              <ListItemIcon>
                <SpeakerNotesIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Call Center Guidelines</ListItemText> 
            </MenuItem>
          )}
          
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>  
          </MenuItem>
        </Menu>

        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
      </header>
        <Suspense fallback={<div className='loader_wrapper'><div className='loader'><img src={Loader} alt='Loading...'/></div></div>}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/appointment/verify/:id" element={<VerifyAppointment />} />
            <Route path="/appointment/details/:id" element={<AppointmentDetails />} />
            <Route path="/appointment/create-appointment/:id" element={<CreateAppointment />} /> 
            <Route path="/consultation-queue-management" element={<ConsultationQueue />} /> 
            <Route path="/consultation-queue-management" element={<ConsultationQueue />} />
            <Route path="/consulted-patients-list" element={<ConsultedPatients />} />
            <Route path="/consulted-patients-list/details/:id" element={<ConsultedPatientDetails />} />
            <Route path="/consulted-patients-list/history/:mobile" element={<ConsultedPatientHistory />} />
            <Route path="/daily-report" element={<DailyReport />} />
            <Route path="/schedule-management" element={<Schedule />} />
            <Route path="/schedule-management/view-schedule/:id" element={<ViewSchedule />} />
            <Route path="/schedule-management/create-new-schedule" element={<CreateSchedule />} />
            <Route path="/consultaion" element={<StartConsultation />} />
            <Route path="/consultaion-history" element={<ConsultationHistory />} />

            <Route path="/service-charge-details" element={<Billing />} />
            <Route path="/add-new-user" element={<AddNewUser />} />
            <Route path="/settings" element={<AppSettings />} />
            <Route path="/call-center-guidelines" element={<AddNewCallCenterGuidelines />} />
            
          </Routes>
        </Suspense>        
      <footer>
        Designed and Developed by Assam Digital.
      </footer>      
    </main>
  );
};

export default Main;
