import React, {useState, useEffect, useContext} from 'react';
import Styles from './LoginScreen.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Logo from '../../assets/SpotBooking.png';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AuthContext from '../../context/auth-context';
import {useSnackbar } from 'notistack';

function LoginScreen(){
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const authCtx = useContext(AuthContext);
    const [showLoader, setShowLoader] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [credError, setCredError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const showNotification = (msg, variant) =>{
        //variants could be success, error, warning, info, or default
        enqueueSnackbar(`${msg}`, { variant });
    }

    useEffect(()=>{
        const identifier = setTimeout(()=>{
            console.log(authCtx.isLogin);
            console.log(authCtx.errorData);
            if(!authCtx.isLogin){
                if(authCtx.errorData.length !== 0){
                    showNotification(`Credentials you have entered do not match our records.`, 'error'); 
                    setCredError(true);
                    setShowLoader(false);
                }
            }        
        },2000); 

        return ()=>{
            clearTimeout(identifier);
        }
        
    }, [authCtx.isLogin, authCtx.errorData]);

    function handleLogin(e){
        e.preventDefault();
        if(userEmail.length === 0 && password.length === 0){
            setEmailError(true);
            setPasswordError(true);
            setCredError(false);
        }
        else if(userEmail.length !==0 && password.length === 0) {
            setEmailError(false);
            setPasswordError(true);
            setCredError(false);
        }
        else if(userEmail.length === 0 && password.length !== 0) {
            setEmailError(true);
            setPasswordError(false);
            setCredError(false);
        }
        else if(userEmail.length !==0 && password.length !== 0){
            setEmailError(false);
            setPasswordError(false);
            setCredError(false);

            setShowLoader(true);
            authCtx.onLogin(userEmail, password);
            if(authCtx.isLogin){
                setShowLoader(false);
            }            
        }
    }

    return(
        <div className={Styles.login_wrapper}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '80%' },
                }}
                noValidate
                autoComplete="off"
                className={Styles.login_form}
                onSubmit={handleLogin}
            >
                <div className={Styles.logo_wrapper}>
                    <img className={Styles.logo} src={Logo} alt='logo' />
                </div>
                
                <TextField
                    required
                    id="loginId"
                    label="E-Mail / Mobile"
                    color="secondary"
                    size="small"
                    onChange={
                        e=>{
                            setCredError(false);
                            e.target.value.length === 0 ? setEmailError(true): setEmailError(false);
                            setUserEmail(e.target.value)
                        }
                    }
                    value={userEmail}
                />
                {
                    emailError && 
                    <Box component="div" className={Styles.validation_error}>
                        E-Mail ID or Mobile Number is required 
                    </Box>
                }
                
                <TextField
                    required
                    id="loginPassword"
                    label="Password"
                    color="secondary"
                    type="password"
                    autoComplete="current-password"
                    size="small"
                    onChange={
                        e=>{
                            setCredError(false);
                            e.target.value.length === 0 ? setPasswordError(true): setPasswordError(false);
                            setPassword(e.target.value)
                        }
                    }
                    value={password}
                />
                {
                    passwordError && 
                    <Box component="div" className={Styles.validation_error}>
                        Password is required 
                    </Box>
                }
                
                {
                    credError && 
                    <Box component="div" className={Styles.validation_error} sx={{width: '100%', padding: '10px', textAlign: 'center'}}>
                        Credentials you have entered do not match our records.<br/>
                        Please enter correct <strong>Email / Mobile</strong> with correct <strong>Password</strong>.
                    </Box>
                }
                
                <Box component="div" className={Styles.login_btn}>
                    {!showLoader &&
                        <>
                            <Button 
                                variant="contained" 
                                size="large" 
                                type='submit' 
                                color='success' 
                                startIcon={<LockOutlinedIcon />}
                                sx={{textTransform: 'capitalize'}}
                            >
                                Login
                            </Button>
                        </>
                    }
                    
                    {showLoader && 
                        <LoadingButton
                            loading
                            startIcon={<LockOpenIcon />}
                            loadingPosition="start"
                            variant="contained" 
                            size="large"
                            color='success'
                            sx={{textTransform: 'capitalize'}}
                        >
                            Login
                        </LoadingButton>
                    }
                    
                </Box>
                
            </Box>
        </div>
    );
}

export default LoginScreen;