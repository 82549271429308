import { he } from 'date-fns/locale';
import React, { useState } from 'react';
import Aside from './Aside';
import Main from './Main';
import TeleVisionDisplay from './components/TeleVisionDisplay/TeleVisionDisplay';

function Layout(props) {

  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const loggedInUserType = localStorage.getItem('loggedInUserType');
  const associatedDoctors = JSON.parse(localStorage.getItem('associatedDoctors'));

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {loggedInUserType !== 'displayscreen' && (
        <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
          <Aside
            image={image}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <Main
            image={image}
            toggled={toggled}
            collapsed={collapsed}
            rtl={rtl}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            handleImageChange={handleImageChange}
          >
          </Main> 
        </div>  
      )}
      
      {loggedInUserType === 'displayscreen' && (
        <TeleVisionDisplay associatedDoctors = {associatedDoctors} />
      )}
    </>
  );
}

export default Layout;
