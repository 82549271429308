import React, {createContext, useState, useEffect} from 'react';
import {axiosClient} from '../BaseURL/baseURL';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
    isLogin:false,
    loginData: {},
    errorData: {},
    onLogout: () => {},
    onLogin: (email, password) => {}
});

export const AuthContextProvider = (props)=>{
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
   
    const [loginData, setLoginData] = useState({
        api_token: "",
        created_at: null,
        email: null,
        id: null,
        mobile: "",
        mobile_verified_at: null,
        name: "",
        updated_at: "",
        user_type: "",
        isError: null
    });

    const [errorData, setErrorData] = useState('');
    
    useEffect(()=>{
        const storedLoggedInUserInfo = localStorage.getItem('isLoggedIn');
        if(storedLoggedInUserInfo === '1'){
            setIsLogin(true);
        }
    }, []);
    
    const logoutHandler = ()=>{
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('loggedInUserId');
        localStorage.removeItem('loggedInUserType');
        localStorage.removeItem('loggedInUserName');
        localStorage.removeItem('doctorId');
        localStorage.removeItem('selectedDoctor');
        localStorage.removeItem('associatedDoctors');
        localStorage.removeItem('access_config');
        localStorage.removeItem('locations_to_display');
        localStorage.removeItem('selected_location_id');
        localStorage.removeItem('selectedLocationObj');
        setErrorData('');
        setIsLogin(false);
        navigate('/');
    }

    const  loginHandler = async (userEmail, password)=>{  
        setErrorData('');
        try{
            const response = await axiosClient.post('/login', {
                mobile: userEmail,
                password: password
            });
            const res = response.data;
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem('token', res.data.api_token);
            setLoginData(prev=>({...prev, ...res.data}));
            setIsLogin(true);
            getUserProfile(res.data.id);
        }
        catch(err) {
            console.error(err);
            setErrorData(err);
        }
    }

    const getUserProfile = async (userId)=>{
        try{
            const res = await axiosClient.get(`/profiles/${userId}`); 
            console.log(res);
            console.log(res.data.locations_to_display.length === 1)
            localStorage.setItem('loggedInUserId', res.data.id);
            localStorage.setItem('loggedInUserName', res.data.name);
            localStorage.setItem('loggedInUserType', res.data.user_type);
            if(res.data.associated_doctors === null){
                localStorage.setItem('associatedDoctors', JSON.stringify([userId]));
            }
            else{
                localStorage.setItem('associatedDoctors', JSON.stringify(res.data.associated_doctors));
            }            
            localStorage.setItem('access_config', JSON.stringify(res.data.access_config));
            localStorage.setItem('locations_to_display', JSON.stringify(res.data.locations_to_display));
            if(res.data.locations_to_display.length === 1){
                localStorage.setItem('selected_location_id', res.data.locations_to_display[0].id);
            }
            else{
                localStorage.setItem('selected_location_id', -99);
            }
            if(res.data.user_type === 'callcenter' || res.data.user_type === 'reception'){
                navigate('/appointment');
            }
            else{
                navigate('/');
            }            
        }
        catch(err){
            console.error(err);
        }
    }

    return <AuthContext.Provider
        value={{
            isLogin: isLogin,
            onLogin: loginHandler,
            onLogout: logoutHandler,
            loginData: loginData,
            errorData: errorData,
        }}
    >
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;