import React, { lazy } from 'react';

export const Dashboard = lazy(() => import('../components/Dashboard/Dashboard'));
export const Appointment = lazy(() => import('../components/Appointment/Appointment'));
export const VerifyAppointment = lazy(() => import('../components/Appointment/VerifyAppointment/VerifyAppointment'));
export const AppointmentDetails = lazy(() => import('../components/Appointment/AppointmentDetails/AppointmentDetails'));
export const CreateAppointment = lazy(() => import('../components/Appointment/CreateAppointment/CreateAppointment'));
export const ConsultationQueue = lazy(() => import('../components/Appointment/ConsultationQueue/ConsultationQueue')); 
export const DailyReport = lazy(() => import('../components/Appointment/DailyReport/DailyReport'));
export const ConsultedPatients = lazy(() => import('../components/ConsultedPatients/ConsultedPatients'));
export const ConsultedPatientDetails = lazy(() => import('../components/ConsultedPatients/ConsultedPatientDetails/ConsultedPatientDetails'));
export const ConsultedPatientHistory = lazy(() => import('../components/ConsultedPatients/ConsultedPatientsHistory/ConsultedPatientsHistory'));
export const Schedule = lazy(() => import('../components/Schedule/Schedule'));
export const ViewSchedule = lazy(() => import('../components/Schedule/ViewSchedule/ViewSchedule'));
export const CreateSchedule = lazy(() => import('../components/Schedule/CreateSchedule/CreateSchedule'));
export const StartConsultation = lazy(() => import('../components/StartConsultation/StartConsultation'));
export const ConsultationHistory = lazy(() => import('../components/ConsultationHistory/ConsultationHistory'));

export const Billing = lazy(() => import('../components/Billing/Billing'));
export const AddNewUser = lazy(() => import('../components/AddNewUser/AddNewUser'));
export const AppSettings = lazy(() => import('../components/Settings/Settings'));
export const AddNewCallCenterGuidelines = lazy(() => import('../components/AddNewCallCenterGuidelines/AddNewCallCenterGuidelines'));
