import React from 'react';
import Styles from './LoaderHeart.module.css';
import Loader from '../../assets/loader.gif';

const LoaderHeart = (props)=>{
    return (
        <>
            {props.display && (
                <div className={Styles.loader_wrapper}>
                 <div className={`${Styles.loader} ${props.className !== 'undefined' ? props.className : ''}`}>
                     <img src={Loader} alt='Loading...'/>
                 </div>
                </div>
            )}
        </>        
    );
}

export default LoaderHeart;